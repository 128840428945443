import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { useMemo } from "react";
import { Invoice } from "../../models/Invoice";

export interface Props {
  invoice: Invoice;
}

export const OrdersSummery = ({ invoice }: Props) => {
  const tax = useMemo(
    () =>
      invoice.dishesSum -
      invoice.discountSum +
      invoice.serviceSum -
      (invoice.dishesSum - invoice.discountSum + invoice.serviceSum) / 1.17,
    [invoice]
  );

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        mb: 3,
      }}
      maxWidth="sm"
    >
      <MoneyTitle title='מע"מ' sum={tax} />
      <MoneyTitle title="הנחה" sum={invoice.discountSum} />
      <MoneyTitle title="שירות" sum={invoice.serviceSum} />
    </Container>
  );
};

export interface MoneyProps {
  title: string;
  sum: number;
}

export const MoneyTitle = ({ title, sum }: MoneyProps) => {
  return (
    <Container disableGutters>
      <Typography textAlign="center" color="text.secondary" variant="subtitle2">
        {title}
      </Typography>
      <Typography
        textAlign="center"
        sx={{
          pt: 1.5,
          flexDirection: "row",
          display: "flex",
          justifyContent: "center",
        }}
        variant="subtitle1"
      >
        {sum.toFixed(2)}
        <Typography
          sx={{
            fontSize: "0.9rem",
            lineHeight: 0.9,
          }}
          variant="subtitle1"
        >
          ₪
        </Typography>
      </Typography>
    </Container>
  );
};
