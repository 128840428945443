import { Box, Container, Divider, Link, Typography, useTheme } from "@mui/material";
import { Head } from "../../components/Head/Head";
import { OrdersSummery } from "../../components/OrderSummery/OrderSummery";
import { PaymentSummery } from "../../components/PaymentSummery/PaymentSummery";
import { Spikes } from "../../components/Spikes/Spikes";
import { TableInfo } from "../../components/TableInfo/TableInfo";
import { Invoice } from "../../models/Invoice";
import "./styles.css";

interface Props {
  invoice: Invoice;
}
export const InvoiceScreen = ({ invoice }: Props) => {
  const theme = useTheme();

  return (
    <Box sx={{ height: "100%", backgroundColor: "primary.dark", p: 3 }}>
      <Spikes side="up" colorUp={theme.palette.primary.dark} color={theme.palette.background.paper} />
      <Container
        disableGutters
        maxWidth="xs"
        sx={{
          backgroundColor: theme.palette.background.paper,
          display: "flex",
          flexDirection: "column",
          p: 0,
        }}
      >
        <Head invoice={invoice} />
        <Divider sx={{ mt: 1 }} />
        <TableInfo invoice={invoice} />
        <OrdersSummery invoice={invoice} />
        <Divider sx={{ mt: 1 }} />
        <PaymentSummery invoice={invoice} />
        <Spikes side="down" colorUp={theme.palette.primary.dark} color={theme.palette.background.paper} />
      </Container>
      <Typography textAlign="center" color="rgba(255, 255, 255, 1)" lineHeight={1.5} sx={{ p: 4 }}>
        מסמך זה אינו מוכר לצורכי מס, לקבלת חשבונית מס יש לפנות לקופה.
      </Typography>
      <Link
        href="http://www.beecomm.com/"
        target="_blank"
        rel="noreferrer"
        underline="always"
        variant="subtitle1"
        textAlign="center"
        color="#FFF"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          fontWeight: 500,
          my: 5,
        }}
      >
        Powered by Beecomm
      </Link>
    </Box>
  );
};
