import { Container, Typography } from "@mui/material";
import { Item } from "../../models/Invoice";

export interface Props {
  item: Item;
}

export const DishItem = ({ item }: Props) => {
  return (
    <Container
      disableGutters
      maxWidth="xs"
      sx={{ display: "flex", flexDirection: "column", width: "100%", my: 2 }}
    >
      <Container disableGutters sx={{ display: "flex", flexDirection: "row" }}>
        <Container disableGutters>
          <Typography
            sx={{
              whiteSpace: "pre-wrap",
              width: "100%",
            }}
            variant="subtitle1"
          >
            {item.name}{item.quantity > 1 ? ` ${item.quantity}X` : ''}
          </Typography>
          <Typography
            sx={{ whiteSpace: "pre-wrap", width: "100%" }}
            variant="caption"
          >
            {item.remark}
          </Typography>
          {item.toppings && (
            <Container disableGutters sx={{ pt: 1.5 }}>
              {Object.values(item.toppings).map((topping) => (
                <Topping
                  disableGutters
                  item={topping}
                  key={topping.dishesInOrderIndex}
                />
              ))}
            </Container>
          )}
        </Container>
        <Container disableGutters sx={{ flex: 1 }} />
        <Typography sx={{ mr: 1, whiteSpace: "nowrap" }} variant="subtitle2">
          {item.totalPrice}
        </Typography>
        <Typography
          sx={{
            fontSize: "0.9rem",
            lineHeight: 0.7,
          }}
          variant="subtitle1"
        >
          ₪
        </Typography>
      </Container>

      {item.subItems && (
        <Container disableGutters sx={{ pb: 1.5 }}>
          {Object.values(item.subItems).map((subItem) => (
            <SubItem item={subItem} key={subItem.dishesInOrderIndex} />
          ))}
        </Container>
      )}
    </Container>
  );
};

export const SubItem = ({ item }: Props) => {
  return (
    <Container
      disableGutters
      maxWidth="xs"
      sx={{
        display: "flex",
        flexDirection: "row",
        pt: 1.5,
      }}
    >
      <Container disableGutters>
        <Typography
          sx={{
            whiteSpace: "pre-wrap",
            color: "text.primary",
            fontSize: "0.95rem",
          }}
          variant="subtitle2"
        >
          {"> "}
          {item.name}{item.quantity > 1 ? ` ${item.quantity}X` : ''}
        </Typography>
        {item.toppings &&
          Object.values(item.toppings).map((topping) => (
            <Topping item={topping} key={topping.dishesInOrderIndex} />
          ))}
      </Container>
      <Typography sx={{ mr: 1, whiteSpace: "nowrap" }} variant="subtitle2">
        {item.totalPrice}
      </Typography>
      <Typography
        sx={{
          fontSize: "0.9rem",
          lineHeight: 0.7,
        }}
        variant="subtitle1"
      >
        ₪
      </Typography>
    </Container>
  );
};

export interface ToppingProps {
  item: Item;
  disableGutters?: boolean;
}

export const Topping = ({ item, disableGutters }: ToppingProps) => {
  return (
    <Container
      disableGutters
      maxWidth="xs"
      sx={{
        width: "auto",
        display: "flex",
        flexDirection: "row",
        pt: disableGutters ? 0.3 : 1.2,
        pb: 1,
      }}
    >
      <Typography
        sx={{
          whiteSpace: "pre-wrap",
          fontSize: "0.85rem",
          color: "primary.dark",
        }}
        variant="subtitle1"
      >
        {item.name}{item.quantity > 1 ? ` ${item.quantity}X` : ''}
      </Typography>
      {item.totalPrice > 0 && (
        <>
          <Typography
            sx={{
              mr: 1,
              whiteSpace: "nowrap",
              fontSize: "0.85rem",
              color: "primary.dark",
            }}
            variant="subtitle1"
          >
            {item.totalPrice}
          </Typography>
          <Typography
            sx={{
              fontSize: "0.65rem",
              lineHeight: 0.75,
              color: "primary.dark",
            }}
            variant="subtitle1"
          >
            ₪
          </Typography>
        </>
      )}
    </Container>
  );
};
