import { useMemo } from "react";
import { Typography } from "@mui/material";
import { Invoice } from "../../models/Invoice";

export interface Props {
  invoice: Invoice;
}

export const CreditDetails = ({ invoice }: Props) => {
  const isCreditPayment = useMemo(
    () => invoice.payment.cardNumber.length > 0,
    [invoice]
  );

  return !isCreditPayment ? null : (
    <>
      <Typography
        textAlign="center"
        sx={{ pt: 3, whiteSpace: "nowrap" }}
        variant="body1"
      >
        {invoice.payment.cardNumber
          ? `בכרטיס ${invoice.payment.cardNumber}*** `
          : ""}
        {invoice.payment.exp_DDMM ? `תוקף ${invoice.payment.exp_DDMM}` : ""}
      </Typography>
    </>
  );
};
