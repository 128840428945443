import { teal } from "@mui/material/colors";
import { createTheme, ThemeOptions } from "@mui/material/styles";

const defaultTheme = createTheme({ direction: "rtl" });

const systemFont = [
  "Heebo",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
];

export const designToken = {
  ...defaultTheme,
  direction: "rtl",
  palette: {
    primary: {
      ...teal,
      main: "#39796b",
      dark: "#003d33",
    },
    secondary: {
      main: "#f4511e",
      dark: "#b91400",
      light: "#ff844c"
    }
  },
  typography: {
    fontFamily: systemFont.join(","),
    fontFamilyCode: [
      "Consolas",
      "Menlo",
      "Monaco",
      "Andale Mono",
      "Ubuntu Mono",
      "monospace",
    ].join(","),
    fontFamilySystem: systemFont.join(","),
    fontWeightSemiBold: 600,
    fontWeightExtraBold: 800,
    h1: {
      fontSize: "clamp(2.625rem, 1.2857rem + 3.5714vw, 4rem)",
      fontWeight: 800,
      lineHeight: 0.5,
    },
    h2: {
      fontSize: "clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)",
      fontWeight: 800,
      lineHeight: 0.5,
    },
    h3: {
      fontSize: defaultTheme.typography.pxToRem(36),
      lineHeight: 0.5,
      letterSpacing: 0.2,
    },
    h4: {
      fontSize: defaultTheme.typography.pxToRem(28),
      lineHeight: 0.5,
      letterSpacing: 0.2,
    },
    h5: {
      fontSize: defaultTheme.typography.pxToRem(24),
      lineHeight: 0.5,
      letterSpacing: 0.1,
    },
    h6: {
      fontSize: defaultTheme.typography.pxToRem(20),
      lineHeight: 0.5,
    },
    button: {
      textTransform: "initial",
      fontWeight: 700,
      letterSpacing: 0,
    },
    subtitle1: {
      fontSize: defaultTheme.typography.pxToRem(18),
      lineHeight: 0.5,
      letterSpacing: 0,
      fontWeight: 500,
      color: defaultTheme.palette.text.primary,
    },
    subtitle2: {
      fontSize: defaultTheme.typography.pxToRem(16),
      lineHeight: 0.5,
      letterSpacing: 0,
      fontWeight: 500,
    },
    body1: {
      fontSize: defaultTheme.typography.pxToRem(16), // 16px
      lineHeight: 0.5,
      letterSpacing: 0,
      fontWeight: "300 !important",
    },
    body2: {
      fontSize: defaultTheme.typography.pxToRem(14), // 14px
      lineHeight: 0.5,
      letterSpacing: 0,
      fontWeight: "300 !important",
    },
    caption: {
      lineHeight: 0,
      fontSize: defaultTheme.typography.pxToRem(12), // 12px
      fontWeight: 300,
    },
  },
} as ThemeOptions;

const theme = createTheme(designToken);

export const useTheme = () => {
  return theme;
};
