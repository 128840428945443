import { Container, Typography } from "@mui/material";
import { useMemo } from "react";
import { Invoice } from "../../models/Invoice";
import { CreditDetails } from "../CreditDetails/CreditDetails";
export interface Props {
  invoice: Invoice;
}

export const PaymentSummery = ({ invoice }: Props) => {
  const orderSum = useMemo(
    () => invoice.dishesSum - invoice.discountSum + invoice.serviceSum,
    [invoice]
  );

  return (
    <Container sx={{ py: 5 }}>
      <Typography
        textAlign="center"
        sx={{ whiteSpace: "nowrap" }}
        variant="subtitle2"
      >
        {`סה"כ לתשלום - ₪${orderSum.toFixed(2)}`}
      </Typography>
      <Typography
        textAlign="center"
        sx={{ pt: 3, whiteSpace: "nowrap" }}
        variant="h5"
      >
        {`התקבל ${
          invoice.payment.paymentTypeName
        } ₪${invoice.payment.paymentSum.toFixed(2)}`}
      </Typography>
      <CreditDetails invoice={invoice} />
    </Container>
  );
};
