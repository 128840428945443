import { Container, Typography } from "@mui/material";
import { useMemo } from "react";
import { Invoice } from "../../models/Invoice";
import { DishesList } from "../Dishes/DishesList";

interface Props {
  invoice: Invoice;
}

export const TableInfo = ({ invoice }: Props) => {
  const orderTypeName = useMemo(() => {
    switch (invoice.tableNumber) {
      case 0:
        return "פיקאפ";
      case -1:
        return "משלוח";
      default:
        return "שולחן";
    }
  }, [invoice]);

  return (
    <Container maxWidth="xs">
      {invoice.tableNumber > 0 && (
        <Typography
          variant="h5"
          textAlign="center"
          sx={{
            pt: 3,
          }}
        >{`${orderTypeName} ${
          invoice.tableNumber > 0 ? invoice.tableNumber : invoice.orderId
        }`}</Typography>
      )}
      <DishesList
        dishesSum={invoice.dishesSum}
        items={Object.values(invoice.items)}
      />
    </Container>
  );
};
