import axios from 'axios';
import { Invoice } from '../models/Invoice';

export interface InvoiceResponse {
  success: boolean;
  data: Invoice | null;
}

export const invoiceApi = axios.create({
  baseURL: 'https://us-central1-beenvoice-557a4.cloudfunctions.net/api',
});
