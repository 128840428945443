import { Container } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { InvoiceContextProvider, useInvoiceContext } from "./context/invoice";
import { InvoiceScreen } from "./Screen/Invoice/Invoice";
import { useTheme } from "./utils/theme";

function App() {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <InvoiceContextProvider>
        <WithInvoice />
      </InvoiceContextProvider>
    </ThemeProvider>
  );
}

const WithInvoice = () => {
  const invoice = useInvoiceContext();
  return (
    <Container disableGutters maxWidth="xl">
      {invoice && <InvoiceScreen invoice={invoice} />}
    </Container>
  );
};

export default App;
