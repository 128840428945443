import { Chip, Container, Divider } from "@mui/material";
import { Item } from "../../models/Invoice";
import { DishItem } from "./DishItem";
import "./styles.css";

interface Props {
  items: Item[];
  dishesSum: number;
}

export const DishesList = ({ items, dishesSum }: Props) => {
  return (
    <Container disableGutters sx={{ pt: 3 }}>
      {items.map((item) => (
        <DishItem key={item.dishesInOrderIndex} item={item} />
      ))}
      <Divider sx={{ my: 3 }}>
        <Chip label={`${dishesSum.toFixed(2)} ₪`} color="primary" variant="outlined" />
      </Divider>
    </Container>
  );
};
