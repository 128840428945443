import { styled } from "@mui/material";

export interface Props {
  side: "up" | "down";
  colorUp: string;
  color: string;
}

export const Spikes = styled("section")<Props>((props) => {
  const color = props.side === 'up' ? props.colorUp : props.color

  return {
    position: "relative",
    background: color,
    "&:after": {
      content: '""',
      position: "absolute",
      right: 0,
      left: "-0%",
      top: "100%",
      zIndex: 10,
      display: "block",
      height: "15px",
      backgroundSize: "15px 100%",
      backgroundImage:
        `linear-gradient(135deg, ${color} 25%, transparent 25%), linear-gradient(225deg, ${color} 25%, transparent 25%)`,
      backgroundPosition: "0 0",
    },
  };
});
