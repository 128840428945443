import Dialog from "@mui/material/Dialog";
import { ErrorOutline } from "@mui/icons-material";
import "./styles.css";
import { Container, useTheme } from "@mui/material";

export const Error = () => {
  const theme = useTheme();

  return (
    <Dialog open={true}>
      <Container sx={{ p: 3, backgroundColor: theme.palette.secondary.main }}>
        <div className="icon-container">
          <ErrorOutline style={{ fontSize: 60, color: "white" }} />
        </div>
      </Container>
      <div dir="rtl" className="content">
        <h1>חשבונית לא נמצאה</h1>
        <p>לא הצלחנו למצוא את החשבונית שלכם. אנא פנו למסעדה לבירורים נוספים</p>
      </div>
    </Dialog>
  );
};
